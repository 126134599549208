import { useEffect, useState } from "react"
import { Button, CircularProgress, Flex, FormControl, FormLabel, Grid, Input, useToast } from "@chakra-ui/react";
import './style.css';
import { useNavigate, useParams } from "react-router-dom";
import { IContractUpdateDto } from "../../../../../interfaces/contract-interface";
import Sidebar from "../../../../../components/Sidebar";
import { getContractById, updateContract } from "../../../../../services/api";

export const ContractFormEdit = () => {
    const [finalCustomerFeePercent, setFinalCustomerFeePercent] = useState<string>("");
    const [companyFeePercent, setCompanyFeePercent] = useState<string>("");
    const [isEnable, setIsEnable] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    let { id, companyId } = useParams();

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const res = await getContractById(Number(id));
        setFinalCustomerFeePercent(res.data.data.final_customer_fee_percent.toString());
        setCompanyFeePercent(res.data.data.company_fee_percent.toString());
        setIsEnable(res.data.data.is_enable ? 1 : 0);

    }

    const handleCreateContract = () => {
        setIsLoading(true);
        const data: IContractUpdateDto = {
            final_customer_fee_percent: finalCustomerFeePercent.toString(),
            company_fee_percent: companyFeePercent.toString(),
            is_enable: isEnable,
            company_id: companyId
        } as IContractUpdateDto;

        updateContract({ data, id: Number(id) }).then(async (res) => {
            await new Promise((resolve, reject) => {
                toast({
                    title: "Contrato atualizado",
                    status: "success",
                    description: res.data.message,
                    duration: 9000,
                    isClosable: true,
                })
                setIsLoading(false);
                resolve(res);
            }).then(() => {
                handleNavigate('/company/' + companyId);
            })
        })
            .catch((err) => {
                setIsLoading(false);
                toast({
                    title: "Erro ao atualizar contrato",
                    description: err.response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            })
    }

    return (
        <>
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <h1>
                                Dados da Contrato
                            </h1>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (Number(companyFeePercent) <= 0 || Number(finalCustomerFeePercent) <= 0) {
                                        toast({
                                            title: "Erro ao criar contrato",
                                            description: "A porcentagem da taxa da empresa e do cliente final devem ser maiores que 0",
                                            status: "error",
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                        return;
                                    }
                                    handleCreateContract();
                                }}
                            >
                                <FormControl className="itemForm">
                                    <FormLabel>
                                        Porcentagem da taxa da empresa
                                    </FormLabel>
                                    <Input required value={companyFeePercent} type="number" placeholder="12" onChange={(e) => setCompanyFeePercent(e.target.value)} />
                                </FormControl>
                                <FormControl className="itemForm">
                                    <FormLabel>
                                        Porcentagem da taxa do cliente final
                                    </FormLabel>
                                    <Input value={finalCustomerFeePercent} required type="number" placeholder="10" onChange={(e) => setFinalCustomerFeePercent(e.target.value)} />
                                </FormControl>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        borderTop: "1px solid #ccc",
                                        padding: "10px",
                                        marginTop: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        colorScheme="red"
                                        style={{
                                            width: "100%",
                                            marginRight: "10px"
                                        }}
                                        onClick={() => {
                                            if (!isLoading) {
                                                handleNavigate('/company/' + companyId);
                                            }
                                        }}
                                        height={50}
                                    >
                                        {isLoading ? <CircularProgress
                                            isIndeterminate
                                            color="blue"
                                            size="24px"
                                        /> : 'Cancelar'}
                                    </Button>

                                    <Button height={50} type="submit" className="itemForm" colorScheme="green">
                                        {
                                            isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : "Salvar"
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div >
                    </div>
                </Flex>
            </Grid>
        </>
    )
}