import React, { useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

import "./style.css";
import Alert from "../../../../components/Alert";
import Drawer from "../../../../components/Drawer";
import {
  deleteTickets,
  updateTickets,
} from "../../../../services/api";
import { useToast } from "@chakra-ui/react";
import TicketForm from "../TicketForm";
import { formatNumberToCurrency } from "../../../../util/currency";
import { ITicket } from "../../../../interfaces/ticket-interface";

interface TicketTableProps {
  tickets: ITicket[];
  list: (eventId: number) => void;
  eventId: number;
}

export default function TicketTable({
  tickets,
  list,
  eventId,
}: TicketTableProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [ticket, setTicket] = useState<ITicket>();

  function middlewareEdit(ticket: ITicket) {
    setTicket(ticket);
    onOpenModal();
  }

  function middlewareDelete(ticket: ITicket) {
    setTicket(ticket);
    onOpen();
  }

  function handleConfirmDelete() {
    if (!ticket?.id) return;

    deleteTickets(ticket.id)
      .finally(() => {
        list(eventId);
        onClose();
        toast({
          title: "Ticket deletado com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao deletar ingresso.",
          description: "Verifique se o ingresso ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function handleUpdate(data: ITicket) {
    if (!ticket?.id) return;

    updateTickets(ticket.id, {...data, eventId})
      .finally(() => {
        list(eventId);
        onCloseModal();
        toast({
          title: "Ticket atualizado com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar ingresso.",
          description: "Verifique se o ingresso ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function renderActionButtons(ticket: ITicket) {
    return (
      <div className="action-buttons">
        <IconButton
          colorScheme="blue"
          aria-label="Editar ingresso"
          icon={<EditIcon />}
          onClick={() => middlewareEdit(ticket)}
        />
        <IconButton
          colorScheme="red"
          aria-label="Deletar ingresso"
          icon={<DeleteIcon />}
          onClick={() => middlewareDelete(ticket)}
        />
      </div>
    );
  }

  return (
    <>
      {tickets.length > 0 ? (
        <TableContainer className="table-container">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width={"2xl"}>Título</Th>
                <Th>Preço</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody className="tr">
              {tickets.map((ticket) => (
                <Tr key={ticket.id}>
                  <Td>{ticket.title}</Td>
                  <Td>{formatNumberToCurrency(ticket.original_price ?? ticket.price)}</Td>
                  <Td>{renderActionButtons(ticket)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={"center"} marginTop={20}>
          Nenhum ingresso cadastrado
        </Text>
      )}

      <Alert
        title={"Deletar ingresso"}
        confirmActionMessage={"Tem certeza que deseja deletar este ingresso ?"}
        confirmActionButtonText={"Deletar"}
        cancelActionButtonText={"Cancelar"}
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        onConfirm={handleConfirmDelete}
        confirmButtonText={""}
      />

      <TicketForm
        onSubmit={handleUpdate}
        initialValues={ticket}
        onClose={onCloseModal}
        isOpen={isOpenModal}
      />
    </>
  );
}
