import { FormControl, FormLabel, Input, Button, Select, Flex, useToast, CircularProgress, Grid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getFranchiseById, updateFranchise } from "../../../../../services/api";
import { isValidCNPJ, isValidCPF } from "../../../../../util/functions";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../../components/Sidebar";
import { IUpdateFranchise } from "../../../../../interfaces/franchise-interface";

export const FranchiseFormEdit = () => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [document_number, setDocumentNumber] = useState<string>("");
    const [type, setType] = useState<string>("individual");
    const [birthdate, setBirthdate] = useState<string>("");
    const [birthdateString, setBirthdateString] = useState<string>("");
    const [professional_occupation, setProfessionalOccupation] = useState<string>("");
    const [ddd, setDdd] = useState<string>("");
    const [number_phone, setNumberphone] = useState<string>("");
    const [type_phone, setTypephone] = useState<string>("");
    const [companyName, setCompanyname] = useState<string>("");
    const [tradingName, setTradingName] = useState<string>("");
    const [annualRevenue, setAnnualRevenue] = useState<string>("");
    const [monthlyIncome, setMonthlyIncome] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [neighborhood, setNeighborhood] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [complementary, setComplementary] = useState<string>("");
    const [reference_point, setReferencePoint] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const toast = useToast();

    let { id } = useParams();

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    const convertDateToISO = (dateString: string) => {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const response = await getFranchiseById(Number(id));
        const { data } = response.data;
        setName(data.name);
        setEmail(data.email);
        setDocumentNumber(data.document_number);
        setType(data.type);
        setBirthdateString(data.birthdate);
        setBirthdate(convertDateToISO(data.birthdate?.toString() || ""));
        setProfessionalOccupation(data.professional_occupation);
        setDdd(data.ddd);
        setNumberphone(data.number_phone);
        setTypephone(data.type_phone);
        setCompanyname(data.company_name);
        setTradingName(data.trading_name);
        if (data.type === "corporation") {
            setAnnualRevenue(data.annual_revenue ? data.annual_revenue.toString() : "");
        } else {
            setMonthlyIncome(data.monthly_income ? data.monthly_income.toString() : "");
        }
        setStreet(data.address.street);
        setNeighborhood(data.address.neighborhood);
        setCity(data.address.city);
        setState(data.address.state);
        setNumber(data.address.number);
        setZip(data.address.zip);
        setComplementary(data.address.complementary || "");
        setReferencePoint(data.address.reference_point || "");
    }

    const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDocumentNumber(value);
        setIsValid(isValidCPF(value));
    };

    const handleChangeCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDocumentNumber(value);
        setIsValid(isValidCNPJ(value));
    };

    const handleCreateFranchise = () => {
        const documentIsValid = type === "individual" ? isValidCPF(document_number) : isValidCNPJ(document_number);
        if (!documentIsValid) {
            toast({
                title: "Erro ao atualizar Franquia",
                description: "CPF/CNPJ inválido",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setIsLoading(true);
        const data: IUpdateFranchise = {
            franchise_dto: {
                name: name,
                email: email,
                document_number: document_number,
                birthdate: birthdate ? birthdateString : null,
                professional_occupation: professional_occupation,
                ddd: ddd,
                number_phone: number_phone,
                type_phone: type_phone,
                annual_revenue: Number(annualRevenue),
                company_name: companyName,
                trading_name: tradingName,
                monthly_income: Number(monthlyIncome),
                type: type
            },
            address_dto: {
                street: street,
                neighborhood: neighborhood,
                city: city,
                state: state,
                number: number,
                zip: zip,
                latitude: '-27.590824',
                longitude: "-48.551262",
                complementary: complementary,
                reference_point: reference_point
            }
        }

        updateFranchise({ data, id: Number(id) }).then(async (response) => {
            await new Promise((resolve) => {
                toast({
                    title: "Franquia atualizada com sucesso.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                setIsLoading(false);
                resolve(response);
            }).then(() => {
                handleNavigate('/franchise/' + id);
            })
        }).catch((error) => {
            console.log(error);
            toast({
                title: "Erro ao atualizar Franquia",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setIsLoading(false);
        });
    }

    const handleChange = (value: string) => {
        setZip(value);
        if (value.length === 8) {
            fetch(`https://viacep.com.br/ws/${value}/json/`)
                .then((response) => response.json())
                .then((data) => {
                    data.logradouro && setStreet(data.logradouro);
                    data.bairro && setNeighborhood(data.bairro);
                    data.localidade && setCity(data.localidade);
                    data.uf && setState(data.uf);
                    console.log(data);
                });
        }
    }

    return (
        <>
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <h1>
                                Dados da Franquia
                            </h1>
                            <form
                                style={{
                                    borderTop: "1px solid #ccc",
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleCreateFranchise();
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            padding: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Nome da Franquia
                                                    <Input value={companyName} required type="text" placeholder="Nome da Franquia..." onChange={(e) => setCompanyname(e.target.value)} />
                                                </FormLabel>

                                            </FormControl>
                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Nome Fantasia
                                                    <Input required value={tradingName} type="text" placeholder="Nome Fantasia..." onChange={(e) => setTradingName(e.target.value)} />
                                                </FormLabel>
                                            </FormControl>
                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Tipo
                                                </FormLabel>
                                                <Select value={type} required placeholder="Selecione o tipo" onChange={(e) => setType(e.target.value)}>
                                                    <option value="individual">Pessoa Fisica</option>
                                                    <option value="corporation">Pessoa Juridica</option>
                                                </Select>

                                            </FormControl>
                                            {
                                                type === "individual" && <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Nome
                                                    </FormLabel>
                                                    <Input required value={name} type="text" placeholder="Nome..." onChange={(e) => setName(e.target.value)} />
                                                </FormControl>
                                            }
                                            {
                                                type === "individual" && <FormControl className="itemForm">
                                                    <FormLabel>
                                                        CPF
                                                    </FormLabel>
                                                    <Input value={document_number} isInvalid={document_number.length >= 11 && !isValid} _valid={{
                                                        colorScheme: document_number.length >= 11 ? isValid ? "green" : "red.500" : "black",
                                                    }} minLength={11} maxLength={11} required type="text" placeholder="CPF..." onChange={(e) => handleChangeCpf(e)} />
                                                    {
                                                        document_number.length >= 11 ? isValid ? (
                                                            <span style={{ color: 'green' }}></span>
                                                        ) : (
                                                            <span style={{ color: 'red' }}>CPF inválido</span>
                                                        )
                                                            : null
                                                    }
                                                </FormControl>
                                            }
                                            {
                                                type === "corporation" && <FormControl className="itemForm">
                                                    <FormLabel>
                                                        CNPJ
                                                    </FormLabel>

                                                    <Input required maxLength={14} isInvalid={document_number.length >= 14 && !isValid} _valid={{
                                                        colorScheme: isValid ? "green" : "red.500",
                                                    }} minLength={14} value={document_number} type="text" placeholder="CNPJ..." onChange={(e) => handleChangeCnpj(e)} />
                                                    {
                                                        document_number.length >= 14 ? isValid ? (
                                                            <span style={{ color: 'green' }}></span>
                                                        ) : (
                                                            <span style={{ color: 'red' }}>CNPJ inválido</span>
                                                        )
                                                            : null
                                                    }
                                                </FormControl>

                                            }
                                            {
                                                type === "individual" && <>
                                                    <FormControl className="itemForm">
                                                        <FormLabel>
                                                            Data de Nascimento
                                                        </FormLabel>
                                                        <Input required type="date" value={birthdate} placeholder="Data de Nascimento..." onChange={(e) => {
                                                            let dayNumber: number = new Date(e.target.value).getDate() + 1;
                                                            let monthNumber: number = new Date(e.target.value).getMonth() + 1;
                                                            let yearNumber: number = new Date(e.target.value).getFullYear();
                                                            let dayString: string = dayNumber.toString();
                                                            let monthString: string = monthNumber.toString();
                                                            let yearString: string = yearNumber.toString();
                                                            if (dayNumber < 10) {
                                                                dayString = `0${dayNumber}`;
                                                            }
                                                            if (monthNumber < 10) {
                                                                monthString = `0${monthNumber}`;
                                                            }
                                                            let fullDate: string = `${dayString}/${monthString}/${yearString}`;

                                                            setBirthdateString(fullDate);
                                                            setBirthdate(e.target.value)
                                                        }} />
                                                    </FormControl>

                                                    <FormControl className="itemForm">
                                                        <FormLabel >
                                                            Profissão
                                                        </FormLabel>
                                                        <Input required value={professional_occupation} type="text" placeholder="Profissão" onChange={(e) => setProfessionalOccupation(e.target.value)} />
                                                    </FormControl>
                                                </>
                                            }

                                            {
                                                type === "individual" && <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Renda Mensal
                                                    </FormLabel>
                                                    <Input required value={monthlyIncome} type="number" placeholder="Renda Mensal..." onChange={(e) => setMonthlyIncome(e.target.value)} />
                                                </FormControl>
                                            }
                                            {
                                                type === "corporation" && <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Renda Anual
                                                    </FormLabel>
                                                    <Input required value={annualRevenue} type="number" placeholder="Renda Anual..." onChange={(e) => setAnnualRevenue(e.target.value)} />
                                                </FormControl>}

                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Email
                                                </FormLabel>
                                                <Input required value={email} type="email" placeholder="Email..." onChange={(e) => setEmail(e.target.value)} />
                                            </FormControl>
                                        </div>

                                        <div style={{
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            padding: "10px",
                                        }}>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around",
                                                    width: "100%",
                                                }}
                                            >
                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Tipo de Telefone
                                                    </FormLabel>
                                                    <Select value={type_phone} required placeholder="Selecione o tipo de telefone" onChange={(e) => setTypephone(e.target.value)}>
                                                        <option value="home">Residencial</option>
                                                        <option value="mobile">Celular</option>
                                                    </Select>
                                                </FormControl>
                                                <Flex>
                                                    <FormControl style={{
                                                        width: "30%",
                                                        padding: "10px"
                                                    }}>
                                                        <FormLabel>
                                                            DDD
                                                        </FormLabel>
                                                        <Input required value={ddd} maxLength={2} minLength={2} type="text" placeholder="DDD" onChange={(e) => setDdd(e.target.value)} />
                                                    </FormControl>

                                                    <FormControl className="itemForm" >
                                                        <FormLabel>
                                                            Telefone
                                                        </FormLabel>
                                                        <Input required maxLength={9} minLength={9} value={number_phone} type="text" placeholder="Telefone" onChange={(e) => setNumberphone(e.target.value)} />
                                                    </FormControl >
                                                </Flex>
                                            </div>
                                            <h1 style={{
                                                borderBottom: "1px solid #ccc",
                                            }} >
                                                Endereço
                                            </h1>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around",
                                                    width: "100%",
                                                }}
                                            >
                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        CEP
                                                    </FormLabel>
                                                    <Input
                                                        required
                                                        className="input"
                                                        type="text"
                                                        name="zip"
                                                        placeholder="CEP"
                                                        maxLength={8}
                                                        onChange={(e) => handleChange(e.target.value)}
                                                        value={zip}
                                                    />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Rua
                                                    </FormLabel>
                                                    <Input required value={street} type="text" placeholder="Rua" onChange={(e) => setStreet(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Bairro
                                                    </FormLabel>
                                                    <Input required value={neighborhood} type="text" placeholder="Bairro" onChange={(e) => setNeighborhood(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Cidade
                                                    </FormLabel>
                                                    <Input required value={city} type="text" placeholder="Cidade" onChange={(e) => setCity(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Estado
                                                    </FormLabel>
                                                    <Input required value={state} type="text" placeholder="Estado" onChange={(e) => setState(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Número
                                                    </FormLabel>
                                                    <Input required value={number} type="text" placeholder="Número" onChange={(e) => setNumber(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Complemento
                                                    </FormLabel>
                                                    <Input required value={complementary} type="text" placeholder="Complemento" onChange={(e) => setComplementary(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Ponto de Referência
                                                    </FormLabel>
                                                    <Input required value={reference_point} type="text" placeholder="Ponto de Referência" onChange={(e) => setReferencePoint(e.target.value)} />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            borderTop: "1px solid #ccc",
                                            padding: "10px",
                                            marginTop: "20px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            colorScheme="red"
                                            style={{
                                                width: "100%",
                                                marginRight: "10px"
                                            }}
                                            onClick={() => {
                                                if (!isLoading) {
                                                    handleNavigate('/franchise/' + id);
                                                }
                                            }}
                                            height={50}
                                        >
                                            {isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Cancelar'}
                                        </Button>
                                        <Button
                                            colorScheme="green"
                                            style={{ width: "100%" }}
                                            type="submit"
                                            height={50}
                                        >
                                            {
                                                isLoading ? <CircularProgress
                                                    isIndeterminate
                                                    color="blue"
                                                    size="24px"
                                                /> : 'Salvar'
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </form >
                        </div >
                    </div>
                </Flex>
            </Grid>
        </>
    );
}