import { useEffect, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    IconButton,
    Text,
    Select,
    Tooltip,
} from "@chakra-ui/react";

import { MdHome } from "react-icons/md";
import "./style.css";
import { listCompanies, listCompaniesWithPagination, verifyCompany } from "../../../services/api";
import { ICompany, ICompanyResponse, IResponseCompanyVerify } from "../../../interfaces/company-interface";
import { useNavigate } from "react-router-dom";
import { ComponentCompaniePending } from "../../Organizer/CompanyTable/components/CompaniPedending";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

export default function CompanyTable() {
    const [companiesPending, setCompaniesPending] = useState<IResponseCompanyVerify>({} as IResponseCompanyVerify);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompany[]>([]);
    const [companyResponse, setCompanyResponse] = useState<ICompanyResponse>({} as ICompanyResponse);
    const [metaPageSize, setMetaPageSize] = useState<number>(10);
    let hasPendeciesboolean = false;

    const navigate = useNavigate()

    const handleNavigate = (path: string, state?: any) => {
        navigate(path, { state });
    }

    const nextPage = () => {
        listCompaniesWithPagination({
            limit: metaPageSize,
            page: companyResponse.data.meta?.currentPage + 1,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const backPage = () => {
        listCompaniesWithPagination({
            limit: metaPageSize,
            page: companyResponse.data.meta?.currentPage - 1,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const firstPage = () => {
        listCompaniesWithPagination({
            limit: metaPageSize,
            page: 1,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const lastPage = () => {
        listCompaniesWithPagination({
            limit: metaPageSize,
            page: companyResponse.data.meta?.totalPages,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const limitListCompany = (limit: number) => {
        listCompaniesWithPagination({
            limit: limit,
            page: companyResponse.data.meta?.currentPage,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }


    const handleVerifyCompany = async (companyId: number): Promise<IResponseCompanyVerify> => {
        setIsLoading(true);
        const response = await verifyCompany(companyId);
        if (response.data.statusCode === 400) {
            hasPendeciesboolean = true
            setCompaniesPending(response.data);
        }
        setIsLoading(false);
        return response.data;
    }

    useEffect(() => {
        // listCompanies().then((response) => {
        //     setCompany(response.data.data);
        // })

        listCompaniesWithPagination({
            limit: metaPageSize,
            page: 1,
        }).then((response) => {
            setCompanyResponse(response.data);
            setCompany(response.data.data.itens);
        }).catch((error) => {
            console.log("error", error);
        });
    }, [])



    return (
        <>
            <TableContainer className="table-container">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th width={"0"}></Th>
                            <Th >Nome</Th>
                            <Th>Email</Th>
                            <Th>Telefone</Th>
                        </Tr>
                    </Thead>
                    <Tbody className="tr">
                        {
                            company?.length === 0 ? (
                                <Tr>
                                    <Td colSpan={4}>Nenhuma empresa encontrada...</Td>
                                </Tr>
                            ) : (
                                company?.map((company) => (
                                    <Tr
                                        onClick={async () => {
                                            const response = await handleVerifyCompany(Number(company.id));
                                            if (hasPendeciesboolean) {
                                                handleNavigate('/organizer/form', { data: response.data })
                                            } else {
                                                handleNavigate(`/company/${company.id}`)
                                            }
                                        }}
                                    >
                                        <Td>
                                            <ComponentCompaniePending companyId={Number(company.id)} />
                                        </Td>
                                        <Td>{company.company_name}</Td>
                                        <Td>{company.email}</Td>
                                        <Td>
                                            <div>
                                                {company.ddd} {company.number_phone}
                                            </div>
                                        </Td>
                                    </Tr>
                                ))
                            )
                        }
                    </Tbody>
                </Table>
                <Flex justifyContent="space-between" m={4} alignItems={"center"}>
                    <Flex>
                        <Tooltip label="Página inicial">
                            <IconButton
                                isDisabled={!companyResponse.data?.meta?.hasPreviousPage}
                                icon={<FaAngleDoubleLeft />}
                                aria-label="Página inicial"
                                margin={4}
                                onClick={firstPage}
                            />
                        </Tooltip>
                        <Tooltip label="Página anterior">
                            <IconButton
                                isDisabled={!companyResponse.data?.meta?.hasPreviousPage}
                                icon={<FaAngleLeft />}
                                aria-label="Página anterior"
                                margin={4}
                                onClick={backPage}
                            />
                        </Tooltip>
                    </Flex>

                    <Flex
                        alignItems={'center'}
                    >
                        <Text flexShrink={"0"} mr={8} >
                            Página{" "}
                            <Text fontWeight="bold" as="span">
                                {companyResponse.data?.meta?.currentPage}
                            </Text>{" "}
                            de{" "}
                            <Text fontWeight="bold" as="span">
                                {companyResponse.data?.meta?.totalPages}
                            </Text>
                        </Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Select
                            w={32}
                            value={metaPageSize}
                            onChange={(e) => {
                                setMetaPageSize(Number(e.target.value));
                                limitListCompany(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Mostrar {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>

                    <Flex
                        alignItems={'center'}
                    >
                        <Tooltip label="Próxima página">
                            <IconButton
                                icon={<FaAngleRight />}
                                aria-label="Próxima página"
                                margin={4}
                                onClick={nextPage}
                                isDisabled={!companyResponse.data?.meta?.hasNextPage}
                            />
                        </Tooltip>
                        <Tooltip label="Última página">
                            <IconButton
                                icon={<FaAngleDoubleRight />}
                                aria-label="Última página"
                                margin={4}
                                onClick={lastPage}
                                isDisabled={!companyResponse.data?.meta?.hasNextPage}
                            />
                        </Tooltip>
                    </Flex>

                </Flex>
            </TableContainer>
        </>
    );
}
